import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../Layout/Header';
import Footer from '../../Layout/Footer';
import PageTitle from './../../Layout/PageTitle'
import HomeOwlSlider2 from './../../Element/HomeOwlSlider2';
import ImgCarouselContent from './../../Element/ImgCarouselContent';
//Images
import bnr from './../../../assets/images/banner/bnr2.jpg';
import bnr1 from './../../../assets/images/background/bg1.jpg';
import bnr2 from './../../../assets/images/background/bg1.png';
import bg15 from './../../../assets/images/background/bg15.jpg';

import { WarrantyService } from '../../../assets/data/footer'

class WarrantyServiceInformation extends Component {
   render() {
      return (
         <>
            <div className="section-full facility bg-gray">
               <div className="row m-a0">
                  {WarrantyService.map((item, index) => (
                     <div className="col-lg-4 col-md-12 col-sm-12 p-a0" key={index}>
                        <div className="icon-bx-wraper left bg-primary text-white p-a70 dlab-box-icon">
                           <div className="icon-lg">
                              <div className="icon-cell">
                                 <div>
                                    <span>
                                       {item.icon}
                                       {item.icon}
                                    </span>
                                 </div>
                              </div>
                           </div>
                           <div className="icon-content">
                              <h4 className="dlab-tilte">{item.title}</h4>
                              <p>Perspiciatis unde omnis ist natus error sit voluptatem accusantium loremque tium totam rem aperiam eaque</p>
                           </div>
                        </div>
                     </div>
                  ))}
               </div>
            </div>
         </>
      )
   }
}

export default WarrantyServiceInformation