import React, { Component } from "react";
import { Link } from 'react-router-dom';
// import { SRLWrapper, useLightbox } from 'simple-react-lightbox';
import Slider from "react-slick";


import item1 from './../../../../assets/images/product/product/item1.jpg';
import item2 from './../../../../assets/images/product/product/item2.jpg';
import item3 from './../../../../assets/images/product/product/item3.jpg';
import item4 from './../../../../assets/images/product/product/item4.jpg';
import item5 from './../../../../assets/images/product/product/item5.jpg';


import thumb1 from './../../../../assets/images/product/product/thumb/item1.jpg';
import thumb2 from './../../../../assets/images/product/product/thumb/item2.jpg';
import thumb3 from './../../../../assets/images/product/product/thumb/item3.jpg';
import thumb4 from './../../../../assets/images/product/product/thumb/item4.jpg';
import thumb5 from './../../../../assets/images/product/product/thumb/item5.jpg';

const imageItem = [
   {
      img: item1,
      thumb: thumb1
   },
   { img: item2, thumb: thumb2 },
   { img: item3, thumb: thumb3 },
   { img: item4, thumb: thumb4 },
];


//Light Gallery on icon click 
const Iconimage = props => {

   // const { openLightbox } = useLightbox()
   // return (
   // 	<Link to={"#"} onClick={() => openLightbox(props.imageToOpen)} className="mfp-link" >
   // 		<i className="ti-fullscreen"></i>
   // 	</Link>
   // )
}
// const [togglerImage, setTogglerImage] = useState(false);

export default class ProductSlider extends Component {
   constructor(props) {
      super(props);
      this.state = {
         nav1: null,
         nav2: null,
         photoIndex: 0,
         togglerImage: false
      };
   }

   componentDidMount() {
      this.setState({
         nav1: this.slider1,
         nav2: this.slider2
      });
   }

   handleToggleImage() {
      this.setState({ togglerImage: !this.state.togglerImage })
   }

   render() {
      var settings = {
         arrows: false,
         centerMode: false,
      };
      const { photoIndex } = this.state;

      return (
         <div>
            <Slider
               asNavFor={this.state.nav2}
               ref={slider => (this.slider1 = slider)}
               className="owl-carousel owl-theme owl-btn-center-lr m-b5 owl-btn-1 primary"
               {...settings}
            >
               {
                  imageItem.map((im) => {
                     return (
                        <div>
                           <div className="item ">
                              <div className="mfp-gallery">
                                 <div className="dlab-box">
                                    {/* <div className="dlab-thum-bx dlab-img-overlay1 "> */}
                                    <img src={im.img} alt="" style={{ width: "100%" }} />
                                    <div className="overlay-bx">
                                       <div className="overlay-icon">
                                          {/* <Iconimage /> */}
                                       </div>
                                    </div>
                                    {/* </div> */}
                                 </div>
                              </div>
                           </div>
                        </div>
                     )
                  })
               }
            </Slider >
            <Slider
               asNavFor={this.state.nav1}
               ref={slider => (this.slider2 = slider)}
               slidesToShow={4}
               swipeToSlide={true}
               focusOnSelect={true}
               className="owl-carousel owl-theme owl-none"
               {...settings}
            >
               {imageItem.map((im) => {
                  return (
                     <div>
                        <div className="item p-1">
                           <div className="dlab-media">
                              <img src={im.thumb} alt="" />
                           </div>
                        </div>
                     </div>
                  )
               })}
            </Slider>
         </div >
      );
   }
}