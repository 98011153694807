import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class HeaderMenuMaster extends Component {
	render() {
		return (
			<>
				<ul className="nav navbar-nav">
					<li><Link to={'/'} className="dez-page">Home</Link></li>
					<li><Link to={'/about-us'} className="dez-page">About Us</Link></li>
					<li><Link to={'/product'} className="dez-page">Product</Link></li>
					<li><Link to={'/faqs'} className="dez-page">FAQ</Link></li>
				</ul>
			</>
		)
	}
}
export default HeaderMenuMaster;