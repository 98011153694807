export const ProductCategories = [
   {
      display: "All Product",
      key: ""
   },
   {
      display: "Agriculture",
      key: "agriculture"
   },
   {
      display: "Hand Made",
      key: "hand-made"
   }]