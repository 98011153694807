import jaheMerah from "../images/products/comodity/jahe-merah.png"
import item2 from "../images/product/item2.jpg"
import ginger from "../images/products/comodity/ginger.png"

export const ProductList = [
   {
      image: jaheMerah,
      type: "natural commodity",
      name: "Checked Short Dress",
      cta: "Checked Short Dress",
      keyName: "jahe-merah",
      category: "agriculture"
   },
   {
      image: item2,
      name: "Dark Brown Boots",
      type: "natural commodity",
      cta: "Checked Short Dress",
      keyName: "dark-brown-boots",
      category: "hand-made",
   },
   {
      image: ginger,
      name: "Ginger",
      type: "natural commodity",
      cta: "Checked Short Dress",
      keyName: "ginger",
      category: "agriculture"
   },
   {
      image: jaheMerah,
      name: "Jahe Merah",
      type: "natural commodity",
      cta: "Checked Short Dress",
      keyName: "jahe-merah-2"
   },
   {
      image: jaheMerah,
      name: "Checked Short Dress",
      type: "natural commodity",
      cta: "Checked Short Dress",
      keyName: "jahe-merah-3"
   },
   {
      image: item2,
      name: "Dark Brown Boots",
      type: "natural commodity",
      cta: "Checked Short Dress",
      keyName: "dark-brown-boots-2"
   },
   {
      image: ginger,
      name: "Ginger",
      type: "natural commodity",
      cta: "Checked Short Dress",
      keyName: "ginger-2"
   },
   {
      image: jaheMerah,
      name: "Jahe Merah",
      type: "natural commodity",
      cta: "Checked Short Dress",
      keyName: "jahe-merah-3"
   }
];