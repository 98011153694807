import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Accordion, ListGroup, useAccordionToggle } from 'react-bootstrap';
import Header from './../../Layout/Header';
import Footer from './../../Layout/Footer';
import PageTitle from './../../Layout/PageTitle'
import { OrderDetail } from './../Login';
// import RangeSlider from './RangeSlider';

import bnr from './../../../assets/images/banner/bnr5.jpg';
import product1 from './../../../assets/images/product/item1.jpg';
import product2 from './../../../assets/images/product/item2.jpg';
import product3 from './../../../assets/images/product/item3.jpg';
import product4 from './../../../assets/images/product/item4.jpg';
import product5 from './../../../assets/images/product/item5.jpg';
import product6 from './../../../assets/images/product/item6.jpg';
import product7 from './../../../assets/images/product/item7.jpg';
import product8 from './../../../assets/images/product/item8.jpg';
import thumb1 from './../../../assets/images/product/thumb/item1.jpg';
import thumb2 from './../../../assets/images/product/thumb/item2.jpg';
import thumb3 from './../../../assets/images/product/thumb/item3.jpg';
import { ProductCategories } from '../../../assets/data/product-categories';
import { ProductList } from '../../../assets/data/products-list';
import ItemProduct from '../../Element/ItemProduct';
import ItemListProduct from '../../Element/ItemListProduct';

const priceBox = [
   { image: product1, title: 'Checked Short Dress' },
   { image: product2, title: 'Slim Fit Chinos' },
   { image: product3, title: 'Dark Brown Boots' },
   { image: product4, title: 'Light Blue Denim Dress' },
   { image: product5, title: 'Green Trousers' },
   { image: product6, title: 'Unisex Sunglasses' },
   { image: product7, title: 'Blue Round-Neck Tshirt' },
   { image: product8, title: 'Men Grey Casual Shoes' },
   { image: product1, title: 'Checked Short Dress' },
   { image: product2, title: 'Slim Fit Chinos' },
   { image: product3, title: 'Dark Brown Boots' },
   { image: product4, title: 'Light Blue Denim Dress' },
];

const widgetsBox = [
   { image: thumb1, }, { image: thumb2, }, { image: thumb3, },
];

class Product extends Component {
   constructor(props) {
      super(props);
      this.props = props
      this.state = {
         dataProduct: [],
         dataFilterProduct: [],
         dataProductCategories: []
      }
   }
   componentDidMount() {

      //Sidebar open and Close 
      // var Closeicon = document.querySelector('.filter-btn');
      this.handleGetProductCategories()
      this.handleGetProductList()
      var sidebaropen = document.querySelector('.filter-left');
      var Closeicon = [].slice.call(document.querySelectorAll('.filter-btn'));
      for (var y = 0; y < Closeicon.length; y++) {
         Closeicon[y].addEventListener('click', toggleFunc);
      }

      function toggleFunc() {
         if ((" " + sidebaropen.className + " ").replace(/[\n\t]/g, " ").indexOf(" filter-on ") > -1) {
            sidebaropen.classList.remove('filter-on');
            sidebaropen.classList.add('filter-off');
         } else {
            sidebaropen.classList.add('filter-on');
            sidebaropen.classList.remove('filter-off');
         }
      }

   }

   handleGetProductList() {
      this.setState({
         dataProduct: ProductList
      })
   }

   handleGetProductCategories() {
      this.setState({
         dataProductCategories: ProductCategories
      })
   }

   handleFilterProductCategories(keyCategory) {
      if (!keyCategory) {
         this.setState({
            dataFilterProduct: []
         })
         return
      }

      const newData = this.state.dataProduct.filter((data) => data?.category === keyCategory)
      this.setState({
         dataFilterProduct: newData
      })
   }




   render() {

      const CustomToggle = ({ children, eventKey, keyCategory }) => {
         const decoratedOnClick = useAccordionToggle(
            eventKey,
            (e) => {
               // var item = e.target.parentNode.children[0];
               // if (item.innerText.includes('▶', 0)) {
               //    item.innerText = item.innerText.replace('▶', '▼');
               // }
               // else {
               //    item.innerText = item.innerText.replace('▼', '▶');
               // }
               this.handleFilterProductCategories(keyCategory)
            }
         );

         return (
            <ListGroup.Item
               onClick={decoratedOnClick}
               action variant="light" as="li"
            // style={{ cursor: 'pointer', paddingBottom: '0', paddingTop: '0' }}
            >
               {children}
            </ListGroup.Item>
         );
      }
      const { dataProduct, dataFilterProduct, dataProductCategories } = this.state
      return (
         <>
            <Header />

            <div className="page-content bg-white">
               {/* <!-- inner page banner --> */}
               <div className="dlab-bnr-inr overlay-primary bg-pt" style={{ backgroundImage: "url(" + bnr + ")" }}>
                  {/* <PageTitle motherMenu="Shop Columns Sidebar" activeMenu="Shop Columns Sidebar" /> */}
                  <PageTitle motherMenu="Product" activeMenu="Product" />
               </div>
               {/* <!-- inner page banner END --> */}
               {/* <!-- contact area --> */}
               <div className="section-full content-inner">
                  {/* <!-- Product --> */}
                  <div className="container">
                     <div className="row sp10-sm">
                        <div className="col-lg-12 col-md-12 col-sm-12 filter-bar">
                           <div className="filter-mobile">
                              <div className="filter-bx">
                                 <select>
                                    <option>Sort By</option>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                 </select>
                              </div>
                              <div className="filter-bx">
                                 <Link to={"#"} className="filter-btn black"><i className="fa fa-filter"></i> Fillter</Link>
                              </div>
                           </div>
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12 sticky-top filter-bar">
                           <aside className="side-bar shop-categories filter-left "  >
                              <Link to={"#"} className="filter-btn close black"><i className="fa fa-close"></i></Link>
                              <div className="widget recent-posts-entry">
                                 <div className="dlab-accordion advanced-search toggle" id="accordion1">
                                    <Accordion defaultActiveKey="0">
                                       <div className="panel">
                                          <div className="acod-head">
                                             <h5 className="acod-title">
                                                <Accordion.Toggle as={Link} variant="link" eventKey="0">Product categories</Accordion.Toggle>
                                             </h5>
                                          </div>
                                          <Accordion.Collapse eventKey="0">
                                             <div className="acod-content">
                                                <div className="widget widget_services">
                                                   {/* <ul>
                                                      <li>
                                                         <a onClick={this.handleFilterProductCategories()}>
                                                            Bags
                                                         </a>
                                                      </li>
                                                      <li>Jeans</li>
                                                      <li>Shoes</li>
                                                      <li>Sweaters</li>
                                                      <li>Tops</li>
                                                      <li>Women</li>
                                                   </ul> */}
                                                   {/* <ListGroup> */}
                                                   {
                                                      dataProductCategories.length > 0 && dataProductCategories.map((c, i) => {
                                                         return (
                                                            <CustomToggle key={i} keyCategory={c.key} >{"▶ " + c.display}</CustomToggle>
                                                         )
                                                      })
                                                   }
                                                   {/* </ListGroup> */}
                                                   {/* <ListGroup >
                                                      <ListGroup.Item
                                                         onClick={this.handleFilterProductCategories()}
                                                         action variant="light" as="li" >
                                                         Light
                                                      </ListGroup.Item>
                                                      <ListGroup.Item
                                                         onSelect={this.handleFilterProductCategories()}
                                                         action variant="light" as="li" >
                                                         Light
                                                      </ListGroup.Item>
                                                      <ListGroup.Item
                                                         onSelect={this.handleFilterProductCategories()}
                                                         action variant="light" as="li" >
                                                         Light
                                                      </ListGroup.Item>
                                                   </ListGroup> */}
                                                </div>
                                             </div>
                                          </Accordion.Collapse>
                                       </div>
                                    </Accordion>
                                    {/* <Accordion defaultActiveKey="1">
                                       <div className="acod-head">
                                          <h5 className="acod-title">
                                             <Accordion.Toggle as={Link} variant="link" eventKey="1">Price Range</Accordion.Toggle>
                                          </h5>
                                       </div>
                                       <Accordion.Collapse eventKey="1" >
                                          <div className="acod-content">
                                             <div className="price-slide-2 range-slider">
                                                <div className="price">
                                                   <input type="text" id="amount-2" className="amount" readOnly="" value="$15 - $100" />
                                                   <div id="slider-range-2">
                                                      <RangeSlider />
                                                   </div>

                                                </div>
                                             </div>
                                          </div>
                                       </Accordion.Collapse>
                                    </Accordion> */}
                                    {/* <Accordion defaultActiveKey="2">
                                       <div className="panel">
                                          <div className="acod-head">
                                             <h5 className="acod-title">
                                                <Accordion.Toggle as={Link} variant="link" eventKey="2">COLOR</Accordion.Toggle>
                                             </h5>
                                          </div>
                                          <Accordion.Collapse eventKey="2" >
                                             <div className="acod-content">
                                                <h6>Select the color</h6>
                                                <div className="btn-group product-item-color" data-toggle="buttons">
                                                   <label className="btn bg-red active">
                                                      <input name="options" id="option1" checked="" type="radio" />
                                                   </label>
                                                   <label className="btn bg-pink">
                                                      <input name="options" id="option2" type="radio" />
                                                   </label>
                                                   <label className="btn bg-yellow">
                                                      <input name="options" id="option3" type="radio" />
                                                   </label>
                                                   <label className="btn bg-blue">
                                                      <input name="options" id="option4" type="radio" />
                                                   </label>
                                                   <label className="btn bg-green">
                                                      <input name="options" id="option5" type="radio" />
                                                   </label>
                                                </div>
                                             </div>
                                          </Accordion.Collapse>
                                       </div>
                                    </Accordion> */}
                                    {/* <Accordion defaultActiveKey="3">
                                       <div className="panel">
                                          <div className="acod-head">
                                             <h5 className="acod-title">
                                                <Accordion.Toggle as={Link} variant="link" eventKey="3">Size</Accordion.Toggle>
                                             </h5>
                                          </div>
                                          <Accordion.Collapse eventKey="3" >
                                             <div className="acod-content">
                                                <h6>Product Size</h6>
                                                <div className="btn-group product-item-size" data-toggle="buttons">
                                                   <label className="btn active">
                                                      <input name="options" id="option6" checked="" type="radio" />XS
                                                   </label>
                                                   <label className="btn">
                                                      <input name="options" id="option7" type="radio" /> LG
                                                   </label>
                                                   <label className="btn">
                                                      <input name="options" id="option8" type="radio" /> MD
                                                   </label>
                                                   <label className="btn">
                                                      <input name="options" id="option9" type="radio" /> SM
                                                   </label>
                                                   <label className="btn">
                                                      <input name="options" id="option10" type="radio" /> Xl
                                                   </label>
                                                </div>
                                             </div>
                                          </Accordion.Collapse>
                                       </div>
                                    </Accordion> */}
                                    {/* <Accordion defaultActiveKey="4">
                                       <div className="panel">
                                          <div className="acod-head">
                                             <h5 className="acod-title">
                                                <Accordion.Toggle as={Link} variant="link" eventKey="4">VENDOR</Accordion.Toggle>
                                             </h5>
                                          </div>
                                          <Accordion.Collapse eventKey="4">
                                             <div className="acod-content">
                                                <div className="product-brand">
                                                   <div className="search-content">
                                                      <input id="seating1" type="checkbox" />
                                                      <label htmlFor="seating1" className="search-content-area">Gap</label>
                                                   </div>
                                                   <div className="search-content">
                                                      <input id="seating2" type="checkbox" />
                                                      <label htmlFor="seating2" className="search-content-area">Guess</label>
                                                   </div>
                                                   <div className="search-content">
                                                      <input id="seating3" type="checkbox" />
                                                      <label htmlFor="seating3" className="search-content-area">Lacoste</label>
                                                   </div>
                                                   <div className="search-content">
                                                      <input id="seating4" type="checkbox" />
                                                      <label htmlFor="seating4" className="search-content-area">Levi's</label>
                                                   </div>
                                                   <div className="search-content">
                                                      <input id="seating5" type="checkbox" />
                                                      <label htmlFor="seating5" className="search-content-area">Polo</label>
                                                   </div>
                                                </div>
                                             </div>
                                          </Accordion.Collapse>
                                       </div>
                                    </Accordion> */}
                                    {/* <Accordion >
                                       <div className="panel">
                                          <div className="acod-head">
                                             <h5 className="acod-title">
                                                <Accordion.Toggle as={Link} variant="link" className="collapsed" eventKey="5">POPULAR TAGS</Accordion.Toggle>
                                             </h5>
                                          </div>
                                          <Accordion.Collapse eventKey="5">
                                             <div className="acod-content">
                                                <div className="widget_tag_cloud radius">
                                                   <div className="tagcloud"> <Link to={"#"} className="mr-1">Design</Link>
                                                      <Link to={"#"} className="mr-1">User interface</Link>
                                                      <Link to={"#"} className="mr-1">SEO</Link>
                                                      <Link to={"#"} className="mr-1">WordPress</Link>
                                                      <Link to={"#"} className="mr-1">Development</Link>
                                                      <Link to={"#"} className="mr-1">Joomla</Link>
                                                      <Link to={"#"} className="mr-1">Design</Link>
                                                      <Link to={"#"} className="mr-1">User interface</Link>
                                                      <Link to={"#"} className="mr-1">SEO</Link>
                                                      <Link to={"#"} className="mr-1">WordPress</Link>
                                                      <Link to={"#"} className="mr-1">Development</Link>
                                                      <Link to={"#"} className="mr-1">Joomla</Link>
                                                      <Link to={"#"} className="mr-1">Design</Link>
                                                      <Link to={"#"} className="mr-1">User interface</Link>
                                                      <Link to={"#"} className="mr-1">SEO</Link>
                                                      <Link to={"#"} className="mr-1">WordPress</Link>
                                                      <Link to={"#"} className="mr-1">Development</Link>
                                                      <Link to={"#"} className="mr-1">Joomla</Link>
                                                   </div>
                                                </div>
                                             </div>
                                          </Accordion.Collapse>
                                       </div>
                                    </Accordion> */}

                                    {/* <Accordion >
                                       <div className="panel">
                                          <div className="acod-head">
                                             <h5 className="acod-title">
                                                <Accordion.Toggle as={Link} variant="link" className="collapsed" eventKey="6">Features</Accordion.Toggle>
                                             </h5>
                                          </div>
                                          <Accordion.Collapse eventKey="6">
                                             <div className="acod-content">
                                                {widgetsBox.map((item, index) => (
                                                   <div className="item-widgets-box" key={index}>
                                                      <div className="item-widgets-left">
                                                         <img src={item.image} alt="" />
                                                      </div>
                                                      <div className="item-widgets-body text-black">
                                                         <h6 className="item-title text-uppercase font-weight-500 m-t0">
                                                            <Link to={"#"}>Product Title</Link>
                                                         </h6>
                                                         <ul className="item-review text-yellow-light">
                                                            <li><i className="fa fa-star mr-1"></i></li>
                                                            <li><i className="fa fa-star mr-1"></i></li>
                                                            <li><i className="fa fa-star mr-1"></i></li>
                                                            <li><i className="fa fa-star-half-o mr-1"></i></li>
                                                            <li><i className="fa fa-star-o mr-1"></i></li>
                                                         </ul>
                                                         <h6 className="item-price m-b0"><del>$232</del> <span className="text-primary">$192</span></h6>
                                                      </div>
                                                   </div>
                                                ))}
                                             </div>
                                          </Accordion.Collapse>
                                       </div>
                                    </Accordion> */}
                                 </div>
                              </div>
                           </aside>
                        </div>
                        <div className="col-lg-9 col-md-12 col-sm-12 ">
                           <div className="row">
                              {dataFilterProduct.length > 0 ? dataFilterProduct.map((data, index) => (
                                 <ItemListProduct productName={data.name} productImage={data.image} cta={data.cta} keyName={data.keyName} data={data} />
                              )) :
                                 dataProduct.map((data, index) => (
                                    <ItemListProduct productName={data.name} productImage={data.image} cta={data.cta} keyName={data.keyName} data={data} />
                                 ))
                              }
                           </div>
                        </div>
                     </div>
                  </div>
                  {/* <!-- Product END --> */}
               </div>
               <OrderDetail />
            </div>

            <Footer />
         </>
      )
   }
}
export default Product;