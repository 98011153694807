import React, { Component } from 'react';
import { Link, useHistory, NavLink } from 'react-router-dom';
// import Header from './../../Layout/Header';
// import Footer from './../../Layout/Footer';
// import PageTitle from './../../Layout/PageTitle';
// import { OrderDetail } from './../Login';
//Images
// import bnr from './../../../assets/images/banner/bnr1.jpg';
import product1 from '../../assets/images/product/item1.jpg';
import product2 from '../../assets/images/product/item2.jpg';
import product3 from '../../assets/images/product/item3.jpg';
import product4 from '../../assets/images/product/item4.jpg';
import product5 from '../../assets/images/product/item5.jpg';
import product6 from '../../assets/images/product/item6.jpg';
import product7 from '../../assets/images/product/item7.jpg';
import product8 from '../../assets/images/product/item8.jpg';
import ginger from '../../assets/images/products/comodity/ginger.png';
import jaheMerah from '../../assets/images/products/comodity/jahe-merah.png';

const itemBox = [
   { image: product1, title: 'Checked Short Dress' },
   { image: product2, title: 'Slim Fit Chinos' },
   { image: product3, title: 'Dark Brown Boots' },
   { image: product4, title: 'Light Blue Denim Dress' },
   { image: product5, title: 'Green Trousers' },
   { image: product6, title: 'Unisex Sunglasses' },
   { image: product7, title: 'Blue Round-Neck Tshirt' },
   { image: product8, title: 'Men Grey Casual Shoes' },
   { image: product1, title: 'Checked Short Dress' },
   { image: product2, title: 'Slim Fit Chinos' },
   { image: product3, title: 'Dark Brown Boots' },
   { image: product4, title: 'Light Blue Denim Dress' },
   { image: ginger, title: 'Ginger' },
   { image: jaheMerah, title: 'Jahe Merah' },
   { image: product4, title: 'Light Blue Denim Dress' },
];

const ItemListProduct = (props) => {
   const { productName, productImage, cta, keyName, data } = props;
   const linkCTA = `https://wa.me/6285175436661/?text=inqury ${encodeURIComponent(cta)}`


   return (
      <>
         <div className="col-lg-4 col-md-6 col-sm-12 col-6">
            <div className="item-box m-b10">
               <div className="item-img">
                  <img src={productImage} alt="" />
                  <div className="item-info-in center">
                     <ul>
                        <li><Link
                           to={{
                              pathname: `/product/${keyName}`,
                              data: data,
                           }}
                        ><i className="ti-eye mr-2"></i></Link></li>
                        <li><a href={linkCTA}><i className="ti-headphone-alt ml-2"></i></a></li>
                     </ul>
                  </div>
               </div>
               <div className="item-info text-center text-black p-a10">
                  <h6 className="item-title font-weight-500">
                     <Link
                        to={{
                           pathname: `/product/${keyName}`,
                           data: data,
                        }}
                     >
                        {productName}
                     </Link>
                  </h6>
               </div>
            </div>
         </div>
      </>
   )
   // }
}
export default ItemListProduct;